// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".D3aYK{grid-template-areas:\"scaleValueLeft  gaplt  sliders   gaprt  scaleValueRight\" \"spacel          gaplb  scaleTime gaprb  spacer\";grid-template-columns:40px 10px 1fr 10px 40px;grid-auto-rows:200px 40px}", ""]);
// Exports
exports.locals = {
	"cgmRangesSlider": "D3aYK"
};
module.exports = exports;
