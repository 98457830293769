// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1sscJ{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;-ms-flex-direction:column;flex-direction:column;margin:20px 0}._1sscJ .blob{-o-object-fit:cover;object-fit:cover}._1sscJ .text{margin:24px 0;font-size:20px;line-height:27px;color:#6f7ea2}.n9red{min-height:65vh;-ms-flex-pack:center;justify-content:center}._3hVy0,.n9red{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}._3hVy0{-ms-flex-pack:justify;justify-content:space-between;position:absolute;top:5px;padding:1.4rem 2rem;border-radius:8px;background:#fff;font-weight:700;font-size:16px;font-size:1.6rem;color:#6f7ea2;-webkit-box-shadow:0 4px 16px rgba(50,66,121,.1);box-shadow:0 4px 16px rgba(50,66,121,.1)}html[dir=ltr] ._3hVy0{right:100px}html[dir=rtl] ._3hVy0{left:100px}._3hVy0 ._3-sZ_{-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg);height:32px;fill:#30a8ff}", ""]);
// Exports
exports.locals = {
	"insufficiencyInfo": "_1sscJ",
	"insufficiencyInfoWrapper": "n9red",
	"insufficiencyDatePopover": "_3hVy0",
	"arrow": "_3-sZ_"
};
module.exports = exports;
